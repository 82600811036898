const Inputmask = require('inputmask/dist/inputmask/inputmask.date.extensions');

exports.maskHour = selector => {
  Inputmask('99:99').mask(selector);
};

exports.maskMoney = selector => {
  Inputmask('(.999){+|1},99', {
    positionCaretOnClick: 'none',
    radixPoint: ',',
    _radixDance: true,
    numericInput: true,
    placeholder: '0',
    definitions: {
      0: {
        validator: '[0-9\uFF11-\uFF19]'
      }
    }
  }).mask(selector);
};
