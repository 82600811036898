exports.connectionStatus = async () => {
    const url = '/v1/minduim/connection/listAllPendenciesAvailableStatus';
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return new Promise((res, rej) => {
        fetch(url, {
            method: 'POST',
            headers
        })
        .then(response => response.json())
        .then(data => {
            res(data);
        })
        .catch(err => {
            res(err);
        });
    });
};


exports.openConnectionModal = async (id) => {
    const data = await window.utils.connectionStatus();
    if (data.status) {
        const val = document.getElementById('proposalNo').value;
      document.getElementById('body-modal-dxc').innerHTML = `
        <div>
            <label>Número da proposta</label>
            <input type='text' name='pno' id='pno' value='${val}' ${val ? 'disabled' : ''} style='margin-bottom: 24px' />
            ${data.status.map((status) => (
                `<div>
                    <input id="dxc${status.id}" type="checkbox" name="dxc" title="${status.title}" value="${status.id}" />  
                    <label for="dxc${status.id}">${status.title}</label>
                </div>`)
            ).join('')}
            <a onclick="utils.openConnection('${id}')" class='btn-success'/> Abrir </a>
        </div>
      `;
    } else {
      document.getElementById('body-modal-dxc').innerHTML = `<p>${JSON.stringify(data)}</p>`;
    }
};

exports.openConnection = async (id) => {
    const url = '/v1/minduim/connection/openProtocol';
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const protocolsHTML = document.querySelectorAll('input[name="dxc"]:checked');
    const protocol = [];
    for (let i = 0; i < protocolsHTML.length; i += 1) {
        protocol.push({
            id: protocolsHTML[i].value,
            title: protocolsHTML[i].getAttribute('title')
        });
    }
    const proposalNo = document.getElementById('pno').value.trim();
    document.getElementById('body-modal-dxc').innerHTML = '<p>Aguarde...</p>';

    return new Promise((res, rej) => {
        fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                proposal: {
                    id: proposalNo,
                    pendencies: protocol
                },
                externalId: id
            }),
        })
        .then(response => response.json())
        .then(response => {
            const { data, statusCode } = response;
            if (statusCode && statusCode !== 200 && statusCode !== 201) {
                document.getElementById('body-modal-dxc').innerHTML = `<p>${data.response && data.response.data ? data.response.data : data.message}</p>`;
            } else {
                document.getElementById('body-modal-dxc').innerHTML = `<p>Protocolo aberto com sucesso (${data.data.protocolo})</p>`;
                window.location.reload();
            }
            res(data);
        })
        .catch(err => {
            document.getElementById('body-modal-dxc').innerHTML = `<p>${err.message}</p>`;
            res(err);
        });
    });
};
