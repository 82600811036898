const { flash, getRelativeLink } = require('./utils');
const { filters } = require('./filters');

const sortOptions = (select) => {
  const options = select.find('option');
  const arr = options.map((_, o) => ({ t: $(o).text(), v: o.value })).get();
  arr.sort((o1, o2) => {
    if (o1.v > o2.v) return 1;
    return o1.v < o2.v ? -1 : 0;
  });
  options.each((i, o) => {
    o.value = arr[i].v;
    $(o).text(arr[i].t);
  });
};

const bindClick = (hidden, source, target, updateFrom) => {
  source.on('click', () => {
    source.find('option:selected').each(function () {
      if ($(this).length) {
        const selText = $(this).text();
        const selVal = $(this).val();
        target.append($('<option />').val(selVal).text(selText));
        source.find(`option[value='${selVal}']`).remove();
        sortOptions(target);
      }
    });

    let optionValues = [];
    let from = null;
    if (updateFrom === 'source') from = source;
    else if (updateFrom === 'target') from = target;
    if (from) {
      const options = from.find('option');
      if (options.length) {
        options.each(function () {
          optionValues.push(`${$(this).val()};${$(this).text()}`);
        });
      } else {
        optionValues = [];
      }
    }
    $(hidden).val(optionValues);
  });
};

exports.selectBindClicks = () => {
  $('form select[selectpartner]').each(function () {
    const $select = $(this);
    const selectcityJson = $select.attr('selectpartner');
    const selectcityData = JSON.parse(selectcityJson);
    const $from = $(selectcityData.from);
    const $state = $(selectcityData.state);

    $state.on('change', () => {
      $('option', $select).remove();
    });

    bindClick(selectcityData.hidden, $from, $select, 'target');
    bindClick(selectcityData.hidden, $select, $from, 'source');
  });
};

exports.populateSelect = {
  bindChange: () => {
    $('form select[populate]').each(function () {
      const $populate = $(this);
      let currentValue = $populate.val();
      const populateJson = $populate.attr('populate');
      const isOptional = $populate.data('optional');
      const populateData = JSON.parse(populateJson);
      const $from = $(populateData.from);
      const onChange = (obj, clear) => {
        let fromValues = '';
        if (obj.length >= 1) {
          for (let i = 0; i < obj.length; i += 1) {
            let value = `${obj[i].value}`;
            if (value) { fromValues += `${value};`; }
          }
        }
        const $options = $populate.find(populateData.matchOptions ? 'option[value=""]' : 'option[value!=""]').remove().end();
        if (fromValues) {
          const excludeOptions = populateData.excludeOptions || [];
          if (clear && populateData.clean && populateData.clean.length) {
            populateData.clean.forEach((element) => {
              $(element).find('option[value!=""]').remove().end();
            });
          }
          const keys = populateData.key.split(',');
          const query = populateData.from
            .split(',')
            .map((current, indice) => {
              const key = keys[indice];
              return key.concat('=').concat($(current).val());
            })
            .join('&');
          let $labelLoading = null;
          let labelText = null;
          if (populateData.loading) {
            $labelLoading = $(`#label-${populateData.loading}`);
            labelText = $labelLoading.text();
            $labelLoading.html(`${labelText} - Carregando`);
          }
          $.ajax({
            url: populateData.url,
            data: query,
            dataType: 'json',
            success: (response) => {
              const values = response[populateData.data];
              if (!populateData.matchOptions) {
                if (isOptional && !$options.find('option[value=""]').length) {
                  $populate.find('option[value=""]').remove().end();
                  $options.append($('<option />').val('').text(''));
                }
                $.each(values, function () {
                  const _id = this._id || this.name;
                  if (!excludeOptions.includes(_id) && _id) {
                    const text = populateData.textDisabledInfo
                      ? `[${this.disabled ? 'D' : 'H'}]: ${this.name}`
                      : this.name;
                    $options.append(
                      $('<option />').val(_id).text(text).attr('selected', this.selected),
                    );
                  }
                });

                if (currentValue) {
                  $populate.val(currentValue);
                }

                if (populateData.loading) $labelLoading.html(labelText);
                filters.setCurrentValue(this);
              } else {
                $.each(values, function () {
                  const _id = this._id || this.name;
                  if (populateData.matchOptions.includes(_id) && _id) {
                    const colors = {
                      alert: '#de3434',
                      warning: '#f5c542',
                      success: '#28a745'
                    };
                    $populate.find(`option[value="${_id}"]`).css({ color: colors[populateData.type] });
                  }
                });
              }

            },
            error: (jqXHR) => {
              const url = getRelativeLink(populateData.url);
              flash(
                'danger',
                `Erro ao tentar recuperar JSON da url (selects) "${url}": ${jqXHR.statusText}`,
              );
            },
          });
        }
      };
      $from.on('change', () => {
        onChange($from, true);
      });
      onChange($from, false);
      $populate.on('change', () => {
        currentValue = $populate.val();
      });
    });
  },
};
