const is = require('./is');

const match = obj => regexp => new RegExp(regexp).match(`${obj}`);
const has = obj => attr => is(obj).typeOfObject() && attr in obj;

const the = obj => (
  {
    match: match(obj),
    has: has(obj),
    is: {
      ...is(obj),
      event: () => has(obj)('isDefaultPrevented')
    }
  }
);
module.exports = the;
