const is = val => ({
  null: () => val === null,
  true: () => !!val,
  false: () => !val,
  array: () => Array.isArray(val),
  string: () => typeof val === 'string' || val instanceof String,
  number: () => typeof val === 'number' || val instanceof Number,
  boolean: () => typeof val === 'boolean' || val instanceof Boolean,
  defined: () => val !== undefined,
  function: () => typeof val === 'function',
  undefined: () => val === undefined,
  typeOfObject: () => typeof val === 'object'
});
module.exports = is;
