const customRegions = (() => {
  // estados disponiveis para a regra de regiao
  const availablesStates = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  // métodos expostos para o Front
  const publics = {};
  // id onde será manipulado o elemento
  const idTarget = 'rulesWhats';
  // Para adicionar mais regras aqui, precisa entrar na order ou na leads, onde será feito o disparo,
  // Cadastrar no customValue quem vai para a jaiminho o parametro que vai ser o valor da condicional,
  // no caso de region, a orders envia para jaiminho no atributo customValue.data o atributo state, que é o estado que foi feito o pedido
  // a jaiminho vai pegar a regra que ta cadastrada aqui, pegar o campo informado e validar se é aplicavel essa regra
  const availablesRules = [{ type: 'region', text: 'Regiões', customValueName: 'data.state' }];
  // Ao adicionar novos valores aqui, necessário adicionar esses novos valores na jaiminho tbm, no arquivo no arquivo sendWhatsapp
  // La tem um depara que pega esses valores, e altera na regra ex:
  // if (currentAlternative.value.dbmCampaign)
  //  rules.dbmCampaign = currentAlternative.value.dbmCampaign;
  const changesInputs = [
    { name: 'dbmCampaign' },
    { name: 'dbmCommercialNumber' },
    { name: 'dbmTemplateCode' },
    { name: 'dbmTemplateName' },
    { name: 'dbmTemplateNamespace' }
  ];
  // Número de regras adicionadas para saber o indice do próximo elemento, ou para remover o ultimo
  const qtdRules = () => document.getElementById(idTarget).childNodes.length;

  // Criacao do campo de regra do lado esquerdo
  // ajustando os valores alternativeValueType e customValueName
  const typeRule = (index, defaultValue) => {
    const leftDiv = document.createElement('div');
    leftDiv.setAttribute('class', 'col-md-3');
    const label = document.createElement('label');
    label.innerText = 'Tipo';
    label.style.marginTop = '5px';

    const select = document.createElement('select');
    select.setAttribute('name', `value[alternativeValueWhen][${index}][alternativeValueType]`);
    select.setAttribute('class', 'form-control');

    const customValue = document.createElement('input');
    customValue.setAttribute('type', 'hidden');
    customValue.setAttribute('value', availablesRules[0].customValueName);
    customValue.setAttribute('name', `value[alternativeValueWhen][${index}][customValueName]`);
    availablesRules.map((a) => {
      const option = document.createElement('option');
      option.setAttribute('value', a.type);
      option.innerText = a.text;
      if (defaultValue && defaultValue.alternativeValueType === a.type) {
        option.setAttribute('selected', 'true');
        customValue.setAttribute('value', defaultValue.customValueName);
      }
      select.appendChild(option);
      return a;
    });
    select.addEventListener('change', (event) => {
      const r = availablesRules.find((e) => e.type === event.target.value);
      event.target.parentNode.lastElementChild.setAttribute('value', r.customValueName);
    });
    leftDiv.appendChild(label);
    leftDiv.appendChild(select);
    // sempre ser o ultimo a ser adicionado para o evento do select nao parar de funcionar
    leftDiv.appendChild(customValue);
    return leftDiv;
  };

  publics.remove = () => {
    document.getElementById(idTarget).removeChild(document.getElementById(idTarget).lastElementChild);
  };

  const rightSide = (index, defaultValue) => {
    const div = document.createElement('div');
    div.setAttribute('class', 'col-md-9');

    const divContent = document.createElement('div');
    divContent.setAttribute('class', 'row');

    /*
      Caso venha novos tipos de regras, pode ser necessário colocar condicoes aqui
    */
    const regions = document.createElement('div');
    regions.setAttribute('class', 'col-md-6');
    regions.style.display = 'flex';
    regions.style.flexWrap = 'wrap';

    availablesStates.map((state) => {
      const input = document.createElement('input');
      const label = document.createElement('label');
      const divContentState = document.createElement('div');
      divContentState.style.width = '50px';
      input.setAttribute('type', 'checkbox');
      input.setAttribute('name', `value[alternativeValueWhen][${index}][region]`);
      input.setAttribute('value', state);
      if (defaultValue && defaultValue.region.find((a) => a === state)) {
        input.setAttribute('checked', 'true');
      }
      label.innerText = state;
      label.style.marginLeft = '8px';
      divContentState.appendChild(input);
      divContentState.appendChild(label);
      regions.appendChild(divContentState);
      return regions;
    });

    const values = document.createElement('div');
    values.setAttribute('class', 'col-md-6');
    changesInputs.forEach((data) => {
      const divContentState = document.createElement('div');
      const label = document.createElement('label');
      const input = document.createElement('input');
      input.setAttribute('class', 'form-control');
      input.setAttribute('name', `value[alternativeValueWhen][${index}][value][${data.name}]`);
      if (defaultValue && defaultValue.value[data.name]) {
        input.setAttribute('value', defaultValue.value[data.name]);
      }
      label.innerText = data.name;
      divContentState.appendChild(label);
      divContentState.appendChild(input);
      values.appendChild(divContentState);
    });
    divContent.appendChild(regions);
    divContent.appendChild(values);
    div.appendChild(divContent);
    return div;
  };

  publics.add = (e) => {
    const size = qtdRules();
    const leftSide = typeRule(size, e);
    const div = document.createElement('div');

    div.setAttribute('class', 'form-group row');
    div.style.backgroundColor = '#CCC';
    div.style.padding = '16px';

    div.appendChild(leftSide);
    div.appendChild(rightSide(size, e));

    document.getElementById(idTarget).appendChild(div);
  };

  publics.init = () => {
    const aux = document.getElementById('baseRule');
    if (aux) {
      const rule = JSON.parse(aux.getAttribute('rule'));
      if (rule && rule.value.alternativeValueWhen) {
        rule.value.alternativeValueWhen.forEach((e) => publics.add(e));
      }
    }
  };

  return publics;
})();

customRegions.init();

module.exports = {
  customRegions,
};
window.customRegions = customRegions;
