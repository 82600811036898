const the = require('./the');

const arrayCopiator = val => ({
  appending: (...add) => [...val, ...add],
  prepending: (...add) => [...add, ...val],
  assigning: () => [...val],
  clonning: () => JSON.parse(JSON.stringify(val))
});

const objCopiator = val => ({
  assigning: (...objs) => Object.assign({}, val, ...objs),
  clonning: () => JSON.parse(JSON.stringify(val))
});

const eventCopiator = val => ({
  as: type => {
    const props = objCopiator(val).assigning({
      type,
      isDefaultPrevented: undefined,
      isImmediatePropagationStopped: undefined,
      isPropagationStopped: undefined
    });
    return jQuery.Event(type, props);
  }
});

const copy = val => {
  if (the(val).is.event()) return eventCopiator(val);
  if (the(val).is.array()) return arrayCopiator(val);
  if (the(val).is.typeOfObject()) return objCopiator(val);
  throw new Error('Not implemented exception');
};

module.exports = copy;
