const datepicker = require('./datepicker');
const { maskHour, maskMoney } = require('./masks');

const urlLike = '.com.br';

const init = () => {
  if (window.location.host.indexOf(urlLike) !== -1) console.error = console.log;
  $('button.hamburger').on('click', function () {
    this.classList.toggle('is-active');
    document.querySelector('.sidebar').classList.toggle('show');
  });

  $('.alert-success, .alert-info')
    .delay(3000)
    .slideUp(200, function () {
      $(this).hide();
    });

  $('[data-toggle="popover"]').popover();
  $('.dropdown-toggle').dropdown();

  datepicker('.datepicker');
  maskHour('.hour');
  maskMoney('.money');

  $('#aboutModal')
    .on('show.bs.modal', function () {
      $(this).find('iframe').attr('src', '/about');
    })
    .on('hidden.bs.modal', function () {
      $(this).find('iframe').removeAttr('src');
    });

  // This function prevents multiple clicks on NextOrder button in orders page
  $('#nextOrder').on('mouseup', function () {
    $(this).css('pointerEvents', 'none');
    $(this).attr('href', '');
  });
};

module.exports = {
  maskMoney,
  init,
};
