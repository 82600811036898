function targetBodyContent(currentIndex, params, defaultValue) {
  const inputHidden = document.createElement('input');
  inputHidden.setAttribute('name', `value[customize][components][${currentIndex}][parameters][${params}][type]`);
  inputHidden.setAttribute('value', defaultValue ? defaultValue.type : 'text');
  inputHidden.setAttribute('type', 'hidden');

  const selectRight = document.createElement('select');
  selectRight.setAttribute('name', `value[customize][components][${currentIndex}][parameters][${params}][field]`);
  selectRight.setAttribute('class', 'form-control');
  [
    { text: 'Nome (Pedido)', value: 'data.name' },
    { text: 'Data de instalação (Pedido)', value: 'data.scheduledInstallationDate' },
    { text: 'Número do pedido (Pedido)', value: 'data.orderNo' },
    { text: 'Endereço Completo (Pedido)', value: 'data.fullAddress' },
    { text: 'Endereço [Rua, Número] (Pedido)', value: 'data.address' },
    { text: 'CEP (Pedido)', value: 'data.zipcode' },
    { text: 'Bairro (Pedido)', value: 'data.district' },
    { text: 'Cidade/UF (Pedido)', value: 'data.city' },
    { text: 'Complemento', value: 'data.complement' },
    { text: 'Referência', value: 'data.reference' },
    { text: 'CNPJ', value: 'data.cnpj' },
    { text: 'CPF', value: 'data.cpf' },
    { text: 'Produtos', value: 'data.products' },
    { text: 'Valor do pedido', value: 'data.value' },
    { text: 'Nome do Produto (internet)', value: 'data.internetProduct' },
    { text: 'Nome do Produto (mobile)', value: 'data.mobileProduct' },
    { text: 'Nome do Produto (phone)', value: 'data.phoneProduct' },
    { text: 'Nome do Produto (tv)', value: 'data.tvProduct' },
    { text: 'Valor do Produto (internet)', value: 'data.internetValue' },
    { text: 'Valor do Produto (mobile)', value: 'data.mobileValue' },
    { text: 'Valor do Produto (phone)', value: 'data.phoneValue' },
    { text: 'Valor do Produto (tv)', value: 'data.tvValue' },
  ].map((a) => {
    const option = document.createElement('option');
    option.setAttribute('value', a.value);
    if (defaultValue && defaultValue.field === a.value) {
      option.setAttribute('selected', 'true');
    }
    option.innerText = a.text;
    selectRight.appendChild(option);
    return a;
  });

  const RightLabel = document.createElement('label');
  RightLabel.innerText = 'Parâmetro';
  RightLabel.style.marginTop = '5px';
  const refDiv = document.createElement('div');
  refDiv.appendChild(RightLabel);
  refDiv.appendChild(selectRight);
  refDiv.appendChild(inputHidden);

  return refDiv;
}

function targetBodyContentImage(currentIndex, params, defaultValue) {
  const inputHidden = document.createElement('input');
  inputHidden.setAttribute('name', `value[customize][components][${currentIndex}][parameters][${params}][type]`);
  inputHidden.setAttribute('value', defaultValue ? defaultValue.type : 'image');
  inputHidden.setAttribute('type', 'hidden');

  const input = document.createElement('input');
  input.setAttribute('name', `value[customize][components][${currentIndex}][parameters][${params}][image][link]`);
  input.setAttribute('class', 'form-control');
  if (defaultValue) {
    input.setAttribute('value', defaultValue.image.link);
  }
  const RightLabel = document.createElement('label');
  RightLabel.innerText = 'URL da imagem';
  RightLabel.style.marginTop = '5px';
  const refDiv = document.createElement('div');
  refDiv.appendChild(RightLabel);
  refDiv.appendChild(input);
  refDiv.appendChild(inputHidden);

  return refDiv;
}

function targetButtons(index) {
  const containersButtons = document.createElement('div');
  containersButtons.style.marginTop = '8px';

  const addParameters = document.createElement('button');
  addParameters.setAttribute('class', 'btn btn-warning custom left-icon');
  addParameters.innerHTML = '<span class="left"><svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path class="not-color" d="M0 0h24v24H0z" fill="none"></path><path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="currentColor"></path></svg></span><span class="text">Parâmetro</span>';
  addParameters.style.marginRight = '8px';
  addParameters.addEventListener('click', function () {
    this.parentNode.parentNode.firstChild.appendChild(targetBodyContent(index, this.parentNode.parentNode.firstChild.childNodes.length));
  });
  addParameters.setAttribute('type', 'button');

  const removeParameters = document.createElement('button');
  removeParameters.setAttribute('type', 'button');
  removeParameters.setAttribute('class', 'btn btn-danger custom left-icon');
  removeParameters.innerHTML = '<span class="left"><svg class="icon" viewBox="0 0 15 17.5" height="17.5" width="15" xmlns="http://www.w3.org/2000/svg"><path transform="translate(-2.5 -1.25)" d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z" id="Fill"></path></svg></span><span class="text">Parâmetro</span>';
  removeParameters.addEventListener('click', function () {
    this.parentNode.parentNode.firstChild.removeChild(this.parentNode.parentNode.firstChild.lastElementChild);
  });
  containersButtons.appendChild(addParameters);
  containersButtons.appendChild(removeParameters);
  return containersButtons;
}

function leftSide(index, defaultValue) {
  const leftDiv = document.createElement('div');
  leftDiv.setAttribute('class', 'col-md-3');
  const label = document.createElement('label');
  label.innerText = 'Tipo';
  label.style.marginTop = '5px';

  const select = document.createElement('select');
  select.setAttribute('name', `value[customize][components][${index}][type]`);
  select.setAttribute('class', 'form-control');
  ['body', 'header'].map((a) => {
    const option = document.createElement('option');
    option.setAttribute('value', a);
    option.innerText = a;
    if (defaultValue === a) {
      option.setAttribute('selected', 'true');
    }
    select.appendChild(option);
    return a;
  });

  select.addEventListener('change', (event) => {
    const rightDiv = event.target.parentNode.parentNode.lastElementChild.firstChild;
    while (rightDiv.firstChild) {
      rightDiv.removeChild(rightDiv.firstChild);
    }
    if (event.target.value === 'header') {
      event.target.parentNode.parentNode.lastElementChild.removeChild(event.target.parentNode.parentNode.lastElementChild.lastElementChild);
      rightDiv.appendChild(targetBodyContentImage(index, 0));
    } else if (event.target.value === 'body') {
      rightDiv.appendChild(targetBodyContent(index, 0));
      event.target.parentNode.parentNode.lastElementChild.appendChild(targetButtons(index));
    }
  });

  leftDiv.appendChild(label);
  leftDiv.appendChild(select);
  return leftDiv;
}

function removeComponents() {
  const size = document.getElementById('add');
  size.removeChild(size.lastElementChild);
}

function addComponents(a) {
  const div = document.createElement('div');
  const size = document.getElementById('add').childNodes.length;
  div.setAttribute('class', 'form-group row');
  div.style.backgroundColor = '#CCC';
  div.style.padding = '16px';

  const rightDiv = document.createElement('div');
  rightDiv.setAttribute('class', 'col-md-9');

  const contentDiv = document.createElement('div');
  if (a && a.parameters) {
    a.parameters.map((c, index) => (a.type === 'body' ? contentDiv.appendChild(targetBodyContent(size, index, c)) : contentDiv.appendChild(targetBodyContentImage(size, index, c))));
  } else {
    contentDiv.appendChild(targetBodyContent(size, 0));
  }
  rightDiv.appendChild(contentDiv);
  if (!a || a.type === 'body') {
    rightDiv.appendChild(targetButtons(size));
  }
  div.appendChild(leftSide(size, a ? a.type : undefined));
  div.appendChild(rightDiv);

  document.getElementById('add').appendChild(div);
}

function onInitialLoad() {
  const aux = document.getElementById('baseRule');
  if (aux) {
    const rule = JSON.parse(aux.getAttribute('rule'));
    console.log(rule);
    if (rule && rule.value.customize) {
      rule.value.customize.components.map((a) => addComponents(a));
    }
  }
}

onInitialLoad();

module.exports = {
  onInitialLoad,
  addComponents,
};
window.removeComponents = removeComponents;
window.addComponents = addComponents;
window.targetBodyContent = targetBodyContent;
window.leftSide = leftSide;
window.targetButtons = targetButtons;
window.onInitialLoad = onInitialLoad;
