require('bootstrap-datepicker/dist/js/bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min');


module.exports = (selector, opts = {}) => $(selector).datepicker(
  { format: 'dd/mm/yyyy', language: 'pt-BR', ...opts }
).on('changeDate', function() {
  const form = $(this).closest('form');
  if (form && form[0].getAttribute('class').indexOf('notrevalidade') >= 0) return;
  if (form.formValidation) form.formValidation('revalidateField', $(this).attr('name'));
});
