

const setCurrentValue = (obj) => {
  const params = window.utils.parseQuery(window.location.search);
  const name = $(obj).attr('id');
  if (params[name]) {
    $(obj).val(params[name]).change();
  }
};

const bindFilters = () => {
  $('.filter-control select.filter').each(function () {
    setCurrentValue(this);
  });
};

module.exports = {
  filters: {
    bindFilters,
    setCurrentValue,
  },
};
