const page = require('page');
const { list, edit, tree, chart, done } = require('./modules');

const router = {
  start: () => {
    page('/users', list, done);
    page('/accessgroups', list, done);
    page('/users/approval', list, done);
    page('/users/disabled', list, done);
    page('/users/locked', list, done);
    page('/affiliates', list, done);
    page('/affiliates/origins', list, done);
    page('/affiliates/disabled', list, done);
    page('/affiliates/:companyid/users', list, done);
    page('/orders', list, done);
    page('/reason', list, done);
    page('/reason/:id', edit, done);
    page('/reason/new', edit, done);
    page('/orderFilters', list, done);
    page('/leadMailtemplates', list, done);
    page('/mailtemplates', list, done);
    page('/coupons', list, done);

    page('/affiliates/dashboard', chart, done);
    page('/affiliates/dashboard/:id', chart, done);
    page('/charts/ordersDayVsDay', chart, done);
    page('/charts/ordersByMonth', chart, done);
    page('/charts/ordersByCombination', chart, done);
    page('/charts/ordersByCity', chart, done);
    page('/charts/ordersByCatalog', chart, done);

    page('/users/new', edit, done);
    page('/users/:id', edit, done);
    page('/users/password/:id', edit, done);
    page('/account', edit, done);
    page('/account/forgot', edit, done);
    page('/account/reset/:token', edit, done);
    page('/account/password', edit, done);
    page('/accessgroups/new', edit, done);
    page('/accessgroups/:id', edit, tree, done);
    page('/affiliates/origins/:id', edit, done);
    page('/affiliates/:id', edit, done);
    page('/orders/:id', edit, done);
    page('/leadMailtemplates/:id', edit, list, done);
    page('/mailtemplates/:id', edit, list, done);
    page('/mailrule/:id', edit, done);
    page('/mailrule/:mailTemplateId/:id', edit, done);
    page('/orderFilters/:id', edit, list, done);
    page('/orderfilterrules/:id/:action', edit, done);
    page('/coupons/:id', edit, done);
    page('/coupons/new', edit, done);

    page('/affiliates/:id/users/new', edit, done);

    // Start without bind popstate and click handlers.
    page.start({
      click: false,
      popstate: false,
    });
  },
};

module.exports = {
  router,
};
