/* eslint-disable no-eval */
/* eslint-disable no-constant-condition */
require('bootstrap');
const globals = require('./libs/globals');
const { goBackWithRefresh, modalDialog, parseQuery } = require('./libs/utils');
const { router } = require('./routes');
require('./libs/rules');
require('./libs/regions');
const conneciton = require('./libs/connection');

const datepicker = require('./libs/datepicker');

// Init globals minduim code
globals.init();


window.changeSelect = (val) => {
  let div = document.createElement('div');
  let input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'customerOrigins[]';
  input.value = val.value;
  let option = document.querySelector(`option[value="${val.value}"]`);
  option.style.display = 'none';

  let label = document.createElement('label');
  label.setAttribute('for', 'myHiddenInput');
  label.innerText = option.innerText;

  let icon = document.createElement('span');
  icon.setAttribute('class', 'glyphicon glyphicon-remove');
  /* eslint-disable-next-line prefer-arrow-callback */
  icon.addEventListener('click', function (element) {
    let div = element.target.parentElement;
    let input = div.querySelector('input');
    document.querySelector(`option[value="${input.value}"]`).style.display = 'block';
    element.target.parentElement.remove();
  });

  div.appendChild(label);
  div.appendChild(input);
  div.appendChild(icon);

  document.getElementById('origins').appendChild(div);
  val.value = '';
};

window.closePanel = () => {
  const sidePanel = document.getElementById('sidePanel');
  sidePanel.style.right = '-500px';
  document.removeEventListener('click', window.handleOutsideClick);
};

window.toast = (message) => {
  document.getElementById('toasts').innerHTML = `<div><p>${message}</p></div>`;
  setTimeout(() => {
    document.getElementById('toasts').innerHTML = '';
  }, 3000);
};

window.handleOutsideClick = (event) => {
  const sidePanel = document.getElementById('sidePanel');
  const table = document.getElementById('tableList');
  if (!sidePanel.contains(event.target) && !table.contains(event.target) && sidePanel.style.right == '0px') {
    event.preventDefault();
    window.closePanel();
  }
};

// Make public some utils functions
window.utils = {
  detailsRules: (ruleString) => {
    const rule = JSON.parse(ruleString);
    const sidePanel = document.getElementById('sidePanel');
    sidePanel.innerHTML = '<div style="display: flex;align-items: center;justify-content: center;height: 100%;position: relative;"><center class="logo"><span class="set-logo"></span></center><div class="loader"></div></div>';

    if (sidePanel.style.right == '0px') {
      document.removeEventListener('click', window.handleOutsideClick);
    } else {
      sidePanel.style.right = '0px';
    }

    fetch(`/jaimrules/${rule._id}?modal=true`)
    .then(response => response.text())
    .then(response => {
        sidePanel.innerHTML = response;
        window.onInitialLoad();
        document.addEventListener('click', window.handleOutsideClick);
        const backButton = document.getElementById('bb');
        backButton.setAttribute('onClick', 'window.closePanel()');
    })
    .catch(error => {
        console.error('Erro:', error);
    });
  },
  datepicker,
  ...conneciton,
  goBackWithRefresh,
  modalDialog,
  parseQuery,
  maskMoney: globals.maskMoney,
  addProduct(size) {
    const block = document.getElementById(`copy${size}`);
    const prds = block.childElementCount;
    const copyContent = document.getElementById('copy').innerHTML
      .replace(/(name="product")/g, `name="combination[${size}][products][${prds}][type]"`)
      .replace(/(name="ids") value="([^"]*)"/g, 'name="ids"')
      .replace(/(name="ids")/g, `name="combination[${size}][products][${prds}][id]"`)
      .replace(/col-md-offset-0"><div class="form-group required"/g, 'col-md-offset-0"><div class="form-group"')
      .replace(/col-md-offset-0"><div class="form-group required has-success"/g, 'col-md-offset-0"><div class="form-group"')
      .replace(/required="required"/g, '');

    const combinationHTML = `
      <div class="item-product" style="background-color: #f4f4f4;padding: 16px;border-radius: 06px;margin-bottom: 16px;">
        <div class="row">
          <div class="col-md-10">${copyContent}</div>
            <div class="col-md-2" style="margin-top: 24px;">
              ${prds > 1 ? '<a onclick="this.parentNode.parentNode.parentNode.remove()" class="btn-primary">Remover</a>' : ''}
            </div>  
          </div>
        </div>
      </div>
    `;
    const aux = document.createElement('div');
    aux.innerHTML = combinationHTML;
    block.appendChild(aux);
  },
  toggleElement(querys) {
    querys.forEach((query) => {
      const display = document.querySelector(query).style.display;
      if (display != 'none') {
        document.querySelector(query).style.display = 'none';
      } else {
        document.querySelector(query).style.display = 'block';
      }
    });
  },
  submitForm(e, callback) {
    e.preventDefault();
    const loadingDiv = document.createElement('div');
    loadingDiv.id = 'loading-div';
    loadingDiv.style.position = 'fixed';
    loadingDiv.style.top = '0';
    loadingDiv.style.left = '0';
    loadingDiv.style.width = '100%';
    loadingDiv.style.height = '100%';
    loadingDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    loadingDiv.style.zIndex = '1000';
    loadingDiv.style.display = 'flex';
    loadingDiv.style.justifyContent = 'center';
    loadingDiv.style.alignItems = 'center';
    loadingDiv.style.color = 'white';
    loadingDiv.style.fontSize = '24px';
    loadingDiv.innerHTML = '<div style="display: flex;align-items: center;justify-content: center;height: 100%;position: relative;"><center class="logo"><span class="set-logo"></span></center><div class="loader"></div></div>';

    document.body.appendChild(loadingDiv);
    const formData = new FormData(e.target);
    const formObject = {};
    const keys = [];
    formData.forEach((value, key) => {
      const aux = key.replace(/\]\[/g, '.').replace(/\]/g, '').replace(/\[/g, '.').split('.')
        // eslint-disable-next-line no-nested-ternary, no-restricted-globals
        .map((a) => (a === '' ? 0 : isNaN(a) ? a : parseInt(a, 10)));
      const keyAux = [];
      let b = `formObject.${keyAux.join('.')}`.replace(/.\[/g, '');
      aux.map((v) => {
        let val = v;
        if (typeof v === 'number') {
          if (v === 0 && eval(`!Array.isArray(${b})`)) {
            // eslint-disable-next-line no-eval
            eval(`${b} = []`);
          }
          val = `[${val}]`;
          // eslint-disable-next-line no-param-reassign
          v += 1;
        }
        keyAux.push(val);
        b = `formObject.${keyAux.join('.')}`.replace(/.\[/g, '[');
        if (!eval(`${b}`)) {
          eval(`${b} = {}`);
        }
        return v;
      });
      if (keys.find((a) => a === b)) {
        if (typeof eval(`!Array.isArray(${b})`)) {
          const currentValue = eval(b);
          eval(`${b} = ['${currentValue}', '${value}']`);
        } else {
          eval(`${b}.push('${value}')`);
        }
      } else {
        keys.push(b);
        eval(`${b} = '${value}'`);
      }
    });
    const xhr = new XMLHttpRequest();
    xhr.open(e.target.getAttribute('method'), e.target.getAttribute('action'));
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onload = function() {
      if (xhr.status === 200) {
        const args = JSON.parse(xhr.responseText);
        // eslint-disable-next-line no-alert
        if (args.message) alert(args.message);
        // eslint-disable-next-line no-alert
        else callback();
      }
      document.body.removeChild(loadingDiv);
      console.log(xhr.responseText);
    };
    xhr.onerror = function() {
      document.body.removeChild(loadingDiv);
    };
    xhr.send(JSON.stringify(formObject));
  },
  addCombination() {
    const block = document.getElementById('block');
    const all = document.querySelectorAll('.item-combination').length;
    const combinationHTML = `
      <div class="row item-combination item-${all}" style="background-color: #FFF;padding-bottom: 24px;padding-top: 24px;border-bottom: 1px solid #CCCC;">
        <div class="col-md-12">
          <div class="form-group required has-feedback">
            <label id="label-discount" for="combination[${all}][discount]">Desconto em reais (R$)</label>
            <div class="input-group">
              <div class="input-group-addon">R$</div>
              <input class="form-control money-${all}" name="combination[${all}][discount]" value="" type="text" required im-insert="true" data-fv-field="discount">
            </div>
          </div>
            </div>
            <div class="col-md-12" id="copy${all}"></div>
            <div class="col-md-12">
              <a class="btn-danger btn-lg" style="margin: 0 auto 24px" onclick="this.parentNode.parentNode.remove()">Remover combinação</a>
              <a class="btn-primary btn-lg" style="margin: 0 auto 24px" onclick="window.utils.addProduct(${all})">Adicionar produto</a>
            </div>
      </div>
    `;
    const aux = document.createElement('div');
    aux.innerHTML = combinationHTML;
    block.appendChild(aux);
    window.utils.maskMoney(`.money-${all}`);
    window.utils.addProduct(all);
    window.utils.addProduct(all);
  }
};

window.actions = {
  tags: {
    onSave: (id, filterId, index) => {
      const options = [];
      const fieldStatus = `tr[data-id="${id}"] td:nth-child(5)`;
      const htmlRules = document.querySelector('.modal-body .filter-control').innerHTML;
      document.querySelectorAll('#orderTag > option').forEach(((a) => options.push(a.textContent)));
      document.querySelector(fieldStatus).textContent = 'SALVANDO...';
      const url = `/orderfilterrules/${filterId}/${index}`;
      const jsonData = {
        tagsList: options
      };
      const xhr = new XMLHttpRequest();
      xhr.open('PATCH', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.onload = function() {
        if (xhr.status === 200) {
          document.querySelector(`[roleid="${id}"]`).setAttribute('newHTML', htmlRules);
          const args = JSON.parse(xhr.responseText);
          if (args.status) {
            document.querySelector(fieldStatus).textContent = options.join(', ');
            return;
          }
        }
        document.querySelector(fieldStatus).textContent = xhr.statusText;
      };
      xhr.onerror = function() {
        document.querySelector(fieldStatus).textContent = 'Erro na requisição';
      };
      xhr.send(JSON.stringify(jsonData));
      return true;
    },
    onOpen: (id) => {
      const ruleId = `[roleid="${id}"]`;
      if (document.querySelector(ruleId).hasAttribute('newHTML')) {
        document.querySelector('.modal-body .filter-control').innerHTML = document.querySelector(ruleId).getAttribute('newHTML');
      }
    }
  },
  subjects: {
    onSave: (id, filterId, index) => {
      const options = [];
      const fieldStatus = `tr[data-id="${id}"] td:nth-child(7)`;
      document.getElementById('reasons').removeAttribute('populate');
      const htmlRules = document.querySelector('.modal-body .filter-control').innerHTML;
      document.querySelectorAll('#reasonsSelected > option').forEach(((a) => options.push(a.textContent)));
      const button = document.querySelector(fieldStatus).cloneNode(true);
      document.querySelector(fieldStatus).textContent = 'SALVANDO...';
      const url = `/orderfilterrules/${filterId}/${index}`;
      const jsonData = {
        reasonsList: options
      };
      const xhr = new XMLHttpRequest();
      xhr.open('PATCH', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.onload = function() {
        if (xhr.status === 200) {
          document.querySelector(`[roleid="${id}"]`).setAttribute('newHTML7', htmlRules);
          const args = JSON.parse(xhr.responseText);
          if (args.status) {
            document.querySelector(fieldStatus).replaceWith(button);
            return;
          }
        }
        document.querySelector(fieldStatus).textContent = xhr.statusText;
      };
      xhr.onerror = function() {
        document.querySelector(fieldStatus).textContent = 'Erro na requisição';
      };
      xhr.send(JSON.stringify(jsonData));
      return true;
    },
    onOpen: (id) => {
      const ruleId = `[roleid="${id}"]`;
      if (document.querySelector(ruleId).hasAttribute('newHTML7')) {
        document.querySelector('.modal-body .filter-control').innerHTML = document.querySelector(ruleId).getAttribute('newHTML7');
      }
    }
  },
  sales: {
    onSave: (id, filterId, index) => {
      const options = [];
      document.getElementById('saleType').removeAttribute('populate');
      const htmlRules = document.querySelector('.modal-body .filter-control').innerHTML;
      document.querySelectorAll('#saleTypeSelected > option').forEach(((a) => options.push(a.textContent)));
      const fieldStatus = `tr[data-id="${id}"] td:nth-child(8)`;
      const button = document.querySelector(fieldStatus).cloneNode(true);
      document.querySelector(fieldStatus).textContent = 'SALVANDO...';
      const url = `/orderfilterrules/${filterId}/${index}`;
      const jsonData = {
        saleTypeList: options
      };
      const xhr = new XMLHttpRequest();
      xhr.open('PATCH', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.onload = function() {
        if (xhr.status === 200) {
          document.querySelector(`[roleid="${id}"]`).setAttribute('newhtml8', htmlRules);
          const args = JSON.parse(xhr.responseText);
          if (args.status) {
            document.querySelector(fieldStatus).replaceWith(button);
            return;
          }
        }
        document.querySelector(fieldStatus).textContent = xhr.statusText;
      };
      xhr.onerror = function() {
        document.querySelector(fieldStatus).textContent = 'Erro na requisição';
      };
      xhr.send(JSON.stringify(jsonData));
      return true;
    },
    onOpen: (id) => {
      const ruleId = `[roleid="${id}"]`;
      if (document.querySelector(ruleId).hasAttribute('newHTML8')) {
        document.querySelector('.modal-body .filter-control').innerHTML = document.querySelector(ruleId).getAttribute('newHTML8');
      }
    },
  },
  date: {
    onSave: (id, filterId, index) => {
      const options = [];
      const fieldStatus = `tr[data-id="${id}"] td:nth-child(9)`;
      const since = document.querySelector('[name="since"]').value;
      const until = document.querySelector('[name="until"]').value;
      document.querySelector('[name="until"]').setAttribute('value', until);
      document.querySelector('[name="since"]').setAttribute('value', since);
      const htmlRules = document.querySelector('.modal-body .filter-control').innerHTML;

      const button = document.querySelector(fieldStatus).cloneNode(true);
      document.querySelector(fieldStatus).textContent = 'SALVANDO...';
      const url = `/orderfilterrules/${filterId}/${index}`;
      const jsonData = {
        dateObj: {
          since, until
        }
      };
      const xhr = new XMLHttpRequest();
      xhr.open('PATCH', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.onload = function() {
        if (xhr.status === 200) {
          document.querySelector(`[roleid="${id}"]`).setAttribute('newHTML9', htmlRules);
          const args = JSON.parse(xhr.responseText);
          if (args.status) {
            document.querySelector(fieldStatus).replaceWith(button);
            return;
          }
        }
        document.querySelector(fieldStatus).textContent = xhr.statusText;
      };
      xhr.onerror = function() {
        document.querySelector(fieldStatus).textContent = 'Erro na requisição';
      };
      xhr.send(JSON.stringify(jsonData));
      return true;
    },
    onOpen: (id) => {
      const ruleId = `[roleid="${id}"]`;
      if (document.querySelector(ruleId).hasAttribute('newHTML9')) {
        document.querySelector('.modal-body .filter-control').innerHTML = document.querySelector(ruleId).getAttribute('newHTML9');
      }
      window.utils.datepicker('.datepicker');
    },
  }
};
// Start routing
router.start();
