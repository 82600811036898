const modules = [
  'list',
  'actionList',
  'edit',
  'tree',
  'chart'
];

module.exports = (() => {
  const loader = { done: () => true };
  modules.forEach(moduleName => {
    loader[moduleName] = (ctx, next) => System.import(`./${moduleName}`) // eslint-disable-line no-undef
        .then(module => {
          if (module && module.init) module.init(ctx);
          next();
        });
  });
  return loader;
})();
