const copy = require('./copy');
// const datepicker = require('./datepicker');

const { selectBindClicks, populateSelect } = require('./selects');

const log = (val) => console.log(val) || val;
const strip = (char) => (str) => str && str.replace(new RegExp(`^${char}+`), '').replace(new RegExp(`${char}+$`), '');
const valid = (val) => !!val;
const stripSlash = (...vals) => vals.filter(valid).map(strip('/'));
const joinPath = (...paths) => stripSlash(...paths).join('/');

const utils = {
  copy,
  log,
  strip,

  getRelativeLinkFrom: (location, path, ...paths) => {
    let base = `${path}`.startsWith('/') ? location.origin : location.href;
    if (base.indexOf('?') !== -1) {
      const splitBase = base.split('?');
      const join = joinPath(splitBase[0], path, ...paths);
      return `${join}?${splitBase[1]}`;
    }
    return joinPath(base, path, ...paths);
  },

  getRelativeLink: (...paths) => utils.getRelativeLinkFrom(window.location, ...paths),

  goBackWithRefresh: () => {
    if ('referrer' in document) {
      window.location.replace(document.referrer);
    } else {
      window.history.back();
    }
  },

  ucfirst: (str = '') => `${str.charAt(0).toUpperCase()}${str.substr(1)}`,

  lcfirst: (str = '') => `${str.charAt(0).toLowerCase()}${str.substr(1)}`,

  flash: (type, flashes) => {
    const messages = Array.isArray(flashes) ? flashes : [flashes];
    const $div = $('<div class="alert alert-dismissible" role="alert" />')
      .addClass(`alert-${type}`)
      .append(
        $('<button class="close" data-dismiss="alert" aria-label="close" />').append(
          $('<span aria-hidden="true" />').html('&times;'),
        ),
      );
    messages.forEach((message) => {
      $div.append($('<ul />').append($('<li />').text(message)));
    });
    utils.hideStopFx(`.flash-messages .alert-${type}`);
    $('.flash-messages').append($div);
    if (type === 'success' || type === 'info') {
      $div.delay(3000).slideUp(200, function () {
        $(this).remove();
      });
    }
    utils.scrollToSelector('.flash-messages');
  },

  modalConfirm: (title, body, next) => new Promise((done, fail) => {
      const $div = $(
        '<div id="modalConfirm" class="modal fade" tabindex="-1" role="dialog" />',
      ).append(
        $('<div class="modal-dialog" role="document" />').append(
          $('<div class="modal-content" />')
            .append(
              $('<div class="modal-header" />')
                .append(
                  $(
                    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" />',
                  ).append($('<span aria-hidden="true">&times;</span>')),
                )
                .append($('<h4 class="modal-title" />').text(title)),
            )
            .append($('<div class="modal-body" />').html(body))
            .append(
              $('<div class="modal-footer" />')
                .append(
                  $('<button type="button" class="btn btn-default" data-dismiss="modal" />').text(
                    'Cancelar',
                  ),
                )
                .append($('<button type="button" class="btn btn-danger" />').text('Confirmar')),
            ),
        ),
      );
      $('body').append($div);
      $('#modalConfirm')
        .modal('show')
        .on('shown.bs.modal', function () {
          $(this)
            .find('.btn-danger')
            .on('click', () => {
              if (next) next();
              $('#modalConfirm').modal('hide');
              done(true);
            })
            .addClass('clicable');
        })
        .on('hidden.bs.modal', () => {
          $('body #modalConfirm').remove();
          fail();
        });
    }),

  modalDialog: (title, body, footer, next, eventOpen) => {
    const $div = $(
      '<div id="modalDialog" class="modal fade" tabindex="-1" role="dialog" />',
    ).append(
      $('<div class="modal-dialog modal-lg" role="document" />').append(
        $('<div class="modal-content" />')
          .append(
            $('<div class="modal-header" />')
              .append(
                $(
                  '<button type="button" class="close" data-dismiss="modal" aria-label="Close" />',
                ).append($('<span aria-hidden="true">&times;</span>')),
              )
              .append($('<h4 class="modal-title" />').text(title)),
          )
          .append($('<div class="modal-body" />').html(body))
          .append($('<div class="modal-footer" />').html(footer)),
      ),
    );
    $('body').append($div);
    $('#modalDialog')
      .modal('show')
      .on('shown.bs.modal', function () {
        if (eventOpen) {
          eventOpen();
        }
        // usado para conseguir fazer as ações de select dentro do modal, os listeners só começam depois que o HTML foi renderizado
        selectBindClicks();
        populateSelect.bindChange();
        // datepicker('.datepicker');

        $(this)
          .find('button[data-action]')
          .on('click', function () {
            const action = $(this).data('action');
            if (next) {
              if (next(action)) $('#modalDialog').modal('hide');
            }
          })
          .addClass('clicable');
      })
      .on('hidden.bs.modal', () => {
        $('body #modalDialog').remove();
      });
  },

  scrollToSelector: (selector, callback, speed = 'slow') => {
    $('html,body').animate(
      { scrollTop: parseInt($(selector).offset().top, 10) },
      speed,
      'swing',
      () => {
        if (callback) callback.call();
      },
    );
  },

  hideStopFx: (selector) => {
    const obj = $(selector);
    if (obj.queue.length > 0) {
      obj.clearQueue();
      obj.stop();
    }
    obj.hide();
  },

  parseLocation: (href) => {
    const a = document.createElement('a');
    a.href = href;
    return a;
  },

  parseQuery: (query) => {
    const params = {};
    let queryParams = [];
    if (query !== '') {
      queryParams = query.replace('?', '').split('&');
    }
    queryParams.forEach((param) => {
      const pair = param.split('=');
      const value = pair[1].replace(/\+/g, '%20');
      params[decodeURIComponent(pair[0])] = decodeURIComponent(value);
    });
    return params;
  },

  updateParams: (params, data) => {
    const updatedParams = {};
    Object.keys(params).forEach((param) => {
      const key = params[param].replace('data.', '');
      if (key in data) {
        updatedParams[param] = data[key];
      } else {
        updatedParams[param] = params[param];
      }
    });
    return updatedParams;
  },

  getQuery: (params) => {
    const queryParams = [];
    Object.keys(params).forEach((queryParam) => {
      const param = [];
      param.push(encodeURIComponent(queryParam));
      param.push(encodeURIComponent(params[queryParam]));
      queryParams.push(param.join('='));
    });
    let query = queryParams.join('&');
    if (query !== '') {
      query = `?${query}`;
    }
    return query;
  },

  ajaxRequest: (params) => {
    $.ajax(params)
      .done((data) => {
        utils.parseResponse(data);
      })
      .fail((jqXHR) => {
        utils.parseError(jqXHR);
      });
  },

  redirect: (redirect) => {
    if (redirect !== undefined) {
      const url = utils.parseLocation(redirect);
      window.location.assign(url.pathname);
    }
  },

  parseResponse: (data) => {
    if (data != null) {
      if (data.redirect !== undefined) {
        utils.redirect(data.redirect);
        return false;
      }
      if (data.info !== undefined) {
        utils.flash('info', data.info);
      }
      if (data.success !== undefined) {
        utils.flash('success', data.success);
      }
      if (data.error !== undefined) {
        utils.flash('danger', data.error);
      }
    }
    return false;
  },

  parseError: (jqXHR) => {
    let error;
    if (jqXHR.responseText !== undefined) {
      try {
        const data = JSON.parse(jqXHR.responseText);
        utils.parseResponse(data);
        return false;
      } catch (e) {
        error = 'Servidor não respondeu um objeto JSON.';
        console.error(jqXHR.responseText);
      }
    }
    utils.flash('danger', error);
    return false;
  },

  revalidateField: (selector) => {
    const form = $(selector).closest('form');
    if (form.formValidation) {
      form.formValidation('revalidateField', $(selector).attr('name'));
    }
  },

  updateFieldStatus: (selector, status) => {
    const form = $(selector).closest('form');
    if (form.formValidation) {
      form.formValidation('updateStatus', $(selector).attr('name'), status);
    }
  },

  getSelectedStatus: () => {
    const statusesNodeList = $("[name='status']");
    const statuses = Array.from(statusesNodeList);
    const status = statuses.find((s) => s.checked) || {};
    return status.value;
  },

  getSelectedReason: () => {
    const reasonsNodeList = $("[name='reason']");
    const reasons = Array.from(reasonsNodeList);
    const reason = reasons.find((r) => !r.disabled) || {};
    return reason.value;
  },
};

module.exports = utils;
